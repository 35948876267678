// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-profile-components-user-container-index-js": () => import("./../../../src/pages/profile/components/UserContainer/index.js" /* webpackChunkName: "component---src-pages-profile-components-user-container-index-js" */),
  "component---src-pages-profile-index-js": () => import("./../../../src/pages/profile/index.js" /* webpackChunkName: "component---src-pages-profile-index-js" */),
  "component---src-pages-register-components-change-password-form-index-js": () => import("./../../../src/pages/register/components/ChangePasswordForm/index.js" /* webpackChunkName: "component---src-pages-register-components-change-password-form-index-js" */),
  "component---src-pages-register-components-registration-form-index-js": () => import("./../../../src/pages/register/components/RegistrationForm/index.js" /* webpackChunkName: "component---src-pages-register-components-registration-form-index-js" */),
  "component---src-pages-register-index-js": () => import("./../../../src/pages/register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */),
  "component---src-pages-reset-password-index-js": () => import("./../../../src/pages/reset-password/index.js" /* webpackChunkName: "component---src-pages-reset-password-index-js" */),
  "component---src-pages-shifts-components-appointment-booking-form-index-js": () => import("./../../../src/pages/shifts/components/AppointmentBookingForm/index.js" /* webpackChunkName: "component---src-pages-shifts-components-appointment-booking-form-index-js" */),
  "component---src-pages-shifts-index-js": () => import("./../../../src/pages/shifts/index.js" /* webpackChunkName: "component---src-pages-shifts-index-js" */)
}

