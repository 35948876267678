import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache
} from "@apollo/client";
import fetch from "isomorphic-fetch";
import { setContext } from "@apollo/client/link/context";
import Cookies from "js-cookie";
// import { cache } from './cache';

function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them

  // const token1 = getCookie("token");
  const token = Cookies.get("userToken");
  // const token = Cookies.get("userToken");
  // console.log("token1", token1);

  return {
    headers: {
      ...headers,
      // authorization: token ? `Bearer ${token}` : "",
      authorization: token
    }
  };
});

const httpLink = createHttpLink({
  // uri: "https://api.annesconcierge.com/graphql",
  uri: process.env.AC_GRAPHQL_URL,
  fetch
});
const link = ApolloLink.from([authLink.concat(httpLink)]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});
